import Coin from './Coin';
import FeatureList from './FeatureList';
import AskAnything from './AskAnything';
import Hero from './Hero';
import Status from './Status';

const Index = () => {
  return (
    <>
      <Hero />
      <Status />
      <AskAnything />
      <FeatureList />
      <Coin />
    </>
  );
};

export default Index;
