import { Link, Navigate, useParams } from "react-router-dom";

import postData from "../mock/BlogData.json";
import BLOGS from "../mock/Blogs";
import classNames, { getTextSize } from "../helpers/string";

import share from "../assets/icons/Share.svg";
import { TOOLS_LINKS } from "../constatnts";

const IS_PRODUCTION = process.env.REACT_APP_PRODUCTION;

const ParagraphWithTitle = ({ topic }) => {
  const textSize = topic.textSize || 1;
  return (
    <div className="flex flex-col gap-[10px]">
      {topic.name ? (
        <h4
          className={classNames(
            getTextSize(textSize),
            "font-semibold leading-[149%] text-start"
          )}
        >
          {topic.name}
        </h4>
      ) : null}
      {topic.description ? (
        <div className="flex flex-col gap-[10px]">
          {topic.description.map((el, id) => (
            <p
              key={id}
              className="text-[18px] sm:text-[20px] font-normal text-[#6C757D] leading-[149%] text-justify"
            >
              {el}
            </p>
          ))}
        </div>
      ) : null}
    </div>
  );
};

const List = ({ topic }) => {
  const textSize = topic.textSize || 1;

  return (
    <div className="flex flex-col gap-[20px]">
      {topic.name ? (
        <h4
          className={classNames(
            getTextSize(textSize),
            "font-semibold leading-[149%] text-start"
          )}
        >
          {topic.name}
        </h4>
      ) : null}
      <ul className="list-disc flex flex-col gap-[10px]">
        {topic.list.map((item, id) => (
          <li
            key={id}
            className="ml-[20px] text-[18px] sm:text-[20px] font-normal text-[#6C757D] leading-[149%] text-justify"
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

const Dialog = ({ topic }) => {
  const textSize = topic.textSize || 1;
  return (
    <div className="flex flex-col gap-[20px]">
      {topic.statements.map((el, id) => (
        <div
          key={id}
          className={classNames(
            getTextSize(textSize),
            "flex items-start justify-start text-justify"
          )}
        >
          <b>{el.name}</b> : <p className="ml-[5px]">{el.description}</p>
        </div>
      ))}
    </div>
  );
};

const Lines = ({ topic }) => {
  return (
    <div className="flex flex-col gap-[20px]">
      {topic.text.map((el, key) =>
        el.link ? (
          <Link
            to={`/blogs/${el.link}` || "/blogs"}
            key={key}
            className="flex px-[20px] py-[10px] items-center justify-start text-white bg-[#1E2D38] transition hover:bg-[#183e56] w-full"
          >
            <p className="font-semibold">{el.title}</p>
          </Link>
        ) : (
          <button
            key={key}
            className="flex px-[20px] py-[10px] items-center justify-start text-white bg-[#1E2D38] w-full"
          >
            <p className="font-semibold">{el.title}</p>
          </button>
        )
      )}
    </div>
  );
};

function getComponent(topic, key) {
  const COMPONENTS = {
    line: Lines,
    paragraph: ParagraphWithTitle,
    list: List,
    dialog: Dialog,
  };
  const TopicComponent = COMPONENTS[topic.type];
  return <TopicComponent topic={topic} key={key} />;
}

const BlogDetails = () => {
  const { link } = useParams();

  const id = BLOGS.findIndex((el) => el.link === link);

  if (id < 0) {
    return <Navigate to="/blogs" />;
  }

  const blogData = BLOGS[id];
  return (
    <div className="bg-white w-full py-[20px] md:py-[40px] relative z-[2] ">
      <div className="flex flex-col md:px-10 mx-auto max-w-3xl lg:max-w-6xl 2xl:max-w-[1000px] px-4">
        <h3 className="leading-[40px] mb-[35px] font-bold text-[22px] sm:text-[30px] lg:text-[36px]">
          {blogData.title}
        </h3>
        <img
          src={blogData.img}
          width={560}
          height={420}
          alt="poster"
          className="w-full h-[50vh] max-h-[500px] object-cover"
        />
        <div className="flex flex-row justify-between gap-x-[10px] items-center flex-wrap sm:flex-nowrap my-[30px]">
          <div className="flex items-center w-full sm:w-auto">
            <img
              src={blogData.avatar}
              width={40}
              height={40}
              alt="avatar"
              className="mr-[10px] rounded-full w-[40px] h-[40px] object-cover"
            />
            <p className="text-[18px] sm:text-[22px] font-bold leading-[20px] sm:leading-[40px]">
              {blogData.name}
            </p>
          </div>

          <div className="flex items-center gap-[20px] md:gap-[40px]">
            <p className="text-[16px] sm:text-[18px] font-[500] text-[#6C757D] leading-[40px]">
              {blogData.date}
            </p>
            <div className="flex items-center">
              <img
                src={share}
                width={15}
                height={16}
                alt="share"
                className="shrink-0 mr-[4px] sm:mr-[6px]"
              />
              <p className="text-[16px] sm:text-[18px] font-[500] text-[#6C757D] leading-[40px]">
                <span className="uppercase">{blogData.shares}</span> shares
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-[40px]">
          {postData[id] ? (
            postData[id].topics.map((topic, id) => getComponent(topic, id))
          ) : (
            <p className="text-[18px] sm:text-[20px] font-normal text-[#6C757D] leading-[149%] text-justify">
              {blogData.description}
            </p>
          )}
        </div>

        <div className="flex items-center justify-end gap-[20px] flex-wrap sm:gap-[40px] my-[60px] sm:my-[80px]">
          <a
            target="blank"
            href={
              IS_PRODUCTION
                ? "https://app.schoolhack.ai/auth/register"
                : "https://dev.schoolhack.ai/auth/register"
            }
            className="min-w-[80px] px-[8px] sm:px-[16px] text-center rounded-[30px] border-black border-[1px] py-[4px] flex-shrink-0 sm:py-[8px] whitespace-nowrap hover:text-white hover:border-black hover:bg-black transition cursor-pointer text-black text-[14px] sm:text-[16px] xl:text-[18px] tracking-wide font-[600]"
          >
            Register
          </a>
          <a
            target="blank"
            href={
              IS_PRODUCTION
                ? "https://app.schoolhack.ai/auth/login"
                : "https://dev.schoolhack.ai/auth/login"
            }
            className="min-w-[80px] px-[8px] sm:px-[16px] text-center rounded-[30px] flex-shrink-0 border-black border-[1px] py-[4px] sm:py-[8px] relative hover:text-white hover:border-black hover:bg-black transition text-black text-[14px] sm:text-[16px] xl:text-[18px] tracking-wide font-[600]"
          >
            Login
          </a>
        </div>
        <h4 className="tex-center mx-auto text-[26px] font-bold">More Tools</h4>
        <div className="flex items-center justify-center gap-[20px] flex-wrap sm:gap-[40px] mt-[20px] sm:mt-[40px] mb-[20px]">
          {TOOLS_LINKS.map((el, id) => (
            <a
              key={id}
              className="text-[14px] sm:text-[16px] xl:text-[18px] px-[8px] sm:px-[16px] py-[4px] sm:py-[8px] border-[#1E2D38] border-[1px] bg-[#1E2D38] transition hover:bg-[#183e56] hover:border-[#183e56]  text-white rounded-[30px] flex-shrink-0 whitespace-nowrap tracking-wide font-[600]"
              target="blank"
              href={el.url}
            >
              {el.name}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
