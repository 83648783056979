import { Link } from "react-router-dom";

import blogs from "../mock/Blogs";

import share from "../assets/icons/Share.svg";

const Blogs = () => {
  return (
    <>
      <div className="blogsBg w-screen h-[70vh] max-h-[800px]">
        <h2 className="text-white text-[24px] sm:text-[36px] md:text-[48px] lg:text-[64px] font-black uppercase tracking-widest text-center px-4 mb-[60px] sm:mb-[40px] md:mb-[80px] lg:mb-[100px]">
          Schoolhack Blogs
        </h2>
      </div>
      <div className="w-full bg-white py-[100px] px-[10px] sm:px-[20px] md:px-[40px] top-[-100px] relative z-10">
        <div className="md:px-10 mx-auto max-w-3xl lg:max-w-6xl 2xl:max-w-[1200px] grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-x-[40px] gap-y-[80px]">
          {blogs.map((el, id) => (
            <Link
              to={`/blogs/${el.link}/`}
              key={id}
              className="linkContainer flex flex-col items-start justify-between shadow-[0_4px_14px_rgba(76,74,69,.2)]"
            >
              <img
                width={560}
                height={420}
                src={el.img}
                alt="poster"
                className="w-full h-[30vh] max-h-[300px] object-cover"
              />
              <h3 className="mx-5 leading-[25px] mt-5 mb-[15px] font-bold text-[22px]">
                {el.title}
              </h3>
              <div className="px-5 w-full flex flex-row justify-between gap-x-[10px] items-center flex-wrap">
                <div className="flex items-center mb-[10px] w-full">
                  <img
                    width={30}
                    height={30}
                    src={el.avatar}
                    alt="avatar"
                    className="object-cover mr-[10px] rounded-full w-[30px] h-[30px]"
                  />
                  <p className="text-[18px] font-bold leading-[30px]">
                    {el.name}
                  </p>
                </div>

                <p className="text-[16px] sm:text-[18px] font-[500] text-[#6C757D] leading-[22px]">
                  {el.date}
                </p>
                <div className="flex items-center">
                  <img
                    width={15}
                    height={16}
                    src={share}
                    alt="share"
                    className="shrink-0 mr-[4px] sm:mr-[6px]"
                  />
                  <p className="text-[16px] sm:text-[18px] font-[500] text-[#6C757D] leading-[22px]">
                    <span className="uppercase">{el.shares}</span> shares
                  </p>
                </div>
              </div>
              <p className="mx-5 mt-[10px] text-[16px] sm:text-[18px] font-normal text-[#6C757D] leading-[120%] text-justify">
                {el.description}
              </p>
              <button className="my-5 mx-5 hoverableLink transition font-bold leading-[40px] text-[18px] sm:text-[22px] underline decoration-1 underline-offset-8">
                View Post
              </button>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
};

export default Blogs;
