import { Section } from '../../components/Section';
import {
  AndroidIcon,
  AppleIcon,
  AppStoreIcon,
  ArrowIcon,
  GooglePlayIcon,
  QuoteIcon,
} from '../../components/icons';

import andyImage from './../../assets/images/askAndy.png';

const Hero = () => {
  return (
    <Section className="grid grid-cols-1 pt-[3rem] lg:grid-cols-2 lg:pt-[10px]">
      <div className="flex h-full flex-col gap-[1.75rem] lg:pt-[6rem]">
        <h1 className="text-[3rem] font-bold leading-[1] text-accent md:text-[3.20356rem]">
          Made for students <br />
          of all ages and <br className="md:hidden" /> levels{' '}
          <br className="hidden lg:block" /> of education
        </h1>
        <p className="text-[1.2rem] leading-[1.38] tracking-[0.02813rem] md:text-[1rem] lg:max-w-[85%]">
          School Hack is the cutting-edge social educational platform driven by artificial intelligence. We cater to students of all ages and diverse educational backgrounds, revolutionizing the way you learn and interact with educational content.
        </p>

        <div className="relative flex w-full flex-col items-center gap-[2.26rem] lg:mx-0 lg:max-w-[unset] lg:flex-row">
          <a
            href="https://apps.apple.com/app/apple-store/id1667172863"
            target="_blank"
            rel="noreferrer noopener nofollow"
            title="AppStore"
            className="flex w-full min-w-[7.5rem] items-center justify-center gap-[1rem] rounded-[2.5rem] border border-white px-[1.25rem] py-[1rem] text-white transition-all hover:scale-[101%] hover:border-accent hover:text-accent md:w-fit"
          >
            <AppleIcon /> Download from App store
          </a>

          <a
            href="https://play.google.com/store/apps/details?id=com.meets.schoolhack&pli=1"
            target="_blank"
            rel="noreferrer noopener nofollow"
            title="GooglePlay"
            className="flex w-full min-w-[7.5rem] items-center justify-center gap-[1rem] rounded-[2.5rem] border border-white px-[1.25rem] py-[1rem] text-white transition-all hover:scale-[101%] hover:border-accent hover:text-accent md:w-fit"
          >
            <AndroidIcon /> Download from Play store
          </a>
        </div>
      </div>
      <div className="mx-auto mt-[2rem] flex h-full w-full border-t border-[#DBDBDB] border-opacity-30 lg:mx-0 lg:mt-[2rem] lg:w-fit lg:flex-col lg:border-0">
        <div className="flex items-center gap-[1.47rem]">
          <QuoteIcon />
          <p className="max-w-[24.5rem] text-[1.5rem] leading-[1.21] tracking-[0.03613rem]">
            Let's dive in and unleash the full potential of School Hack
            together!
          </p>
        </div>
        <div className="hidden self-center overflow-hidden rounded-full lg:block">
          <img src={andyImage} alt="Andy" />
        </div>
      </div>
    </Section>
  );
};

export default Hero;
