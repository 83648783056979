import { DiscordIcon, FacebookIcon, InstagramIcon, XIcon } from "./icons";

const SOCIAL_LINKS = [
      // {
      //       href: '',
      //       Icon: FacebookIcon
      // }, {
      //       href: '',
      //       Icon: InstagramIcon
      // }, 
      {
            href: 'https://x.com/schoolhackcoin',
            Icon: XIcon
      }, {
            href: 'https://discord.gg/schoolhack',
            Icon: DiscordIcon
      }
]

const Socials = () => {
      return (
            <ul className="flex gap-[1.5rem]">
                  {SOCIAL_LINKS.map(link =>
                        <li key={link.href}>
                              <a href={link.href}
                                    target="_blank"
                                    rel="noreferrer noopener nofollow"
                                    className="text-white transition-all hover:text-accent"
                              >
                                    <link.Icon />
                              </a>
                        </li>)}
            </ul>);
}

export default Socials;