import { Section } from '../../components/Section';
import { AndroidIcon, AppleIcon, ArrowIcon } from '../../components/icons';

import goldSH from './../../assets/images/GoldSH.png';
import andyImage from './../../assets/images/askAndy.png';

const Coin = () => {
  return (
    <div className="coinBg mt-[8rem] overflow-hidden rounded-[2.5rem] min-[1260px]:rounded-none">
      <div className="bg-[#202020] p-[1.56rem] backdrop-blur-sm lg:bg-white/20">
        <Section className="flex flex-col justify-between min-[1260px]:flex-row">
          <div className="flex flex-col items-center gap-[1.2rem] min-[1260px]:w-[60%] min-[1260px]:flex-row min-[1260px]:gap-[2.41rem] min-[1260px]:pb-[5.81rem] min-[1260px]:pt-[7.31rem]">
            <div className="order-2 flex flex-col items-center gap-[1.2rem] min-[1260px]:items-start min-[1260px]:gap-[2.41rem]">
              <h3 className="text-center text-[3.2rem] font-extrabold leading-[3.2rem] min-[1260px]:text-[4.71494rem] min-[1260px]:leading-[4.71494rem]">
                School Hack
              </h3>
              <a
                href="https://www.shcoin.ai/"
                target="_blank"
                rel="noreferrer noopener nofollow"
                className="group flex items-center gap-[1rem]"
              >
                <span className="text-[3.2rem] font-light leading-[3.2rem] text-[#E0A03B] min-[1260px]:text-[4.71494rem] min-[1260px]:leading-[4.71494rem]">
                  Coin
                </span>
                <div className="hidden w-[50%] translate-x-0 text-[#987f44] transition-all group-hover:translate-x-[1rem] min-[1260px]:block [&>svg]:h-[3rem] [&>svg]:w-[8rem]">
                  <ArrowIcon />
                </div>
              </a>

              <p className="max-w-[40rem] text-center font-normal leading-[187.5%] tracking-[0.02088rem] min-[1260px]:text-start">
                School Hack Coin will help students in developing countries who
                want to access quality education by lowering the entry barriers
                to our learning platform and facilitating our expansion and
                development initiatives
              </p>
            </div>
            <img
              src={goldSH}
              alt="SchoolHack"
              className="order-1 h-[7.4rem] w-[7.4rem] min-[1260px]:order-2 min-[1260px]:h-[17rem] min-[1260px]:w-[17rem]"
            />
          </div>

          <div className="relative hidden min-[1260px]:block">
            <div className="andyShadow absolute left-1/2 top-1/2 h-[40rem] w-[40rem] -translate-x-1/2 -translate-y-1/2 rounded-full blur-[100px]" />
            <img
              src={andyImage}
              alt="Andy"
              className="relative -bottom-[5rem] h-[28rem] w-[28rem]"
            />
          </div>

          <div className="mt-[1.5rem] flex flex-col items-center gap-[1.5rem] border-t border-[#6d6d6d] pt-[1.5rem] min-[1260px]:hidden">
            <span className="text-[1.837rem] font-light">Available on</span>

            <a
              href="https://apps.apple.com/us/app/school-hack/id1667172863"
              target="_blank"
              rel="noreferrer noopener nofollow"
              className="relative flex w-full max-w-[24rem] items-center justify-center gap-[0.94rem] rounded-[2.5rem] border border-white px-[1.25rem] py-[1rem] text-center text-[1.165rem] leading-[1.5rem] text-white transition-all hover:bg-white hover:text-black"
            >
              <AppleIcon />
              <span>Download from App store</span>
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=com.meets.schoolhack&pli=1"
              target="_blank"
              rel="noreferrer noopener nofollow"
              className="relative flex w-full max-w-[24rem] items-center justify-center gap-[0.94rem] rounded-[2.5rem] border border-white px-[1.25rem] py-[1rem] text-center text-[1.165rem] leading-[1.5rem] text-white transition-all hover:bg-white hover:text-black"
            >
              <AndroidIcon />
              <span>Download from Play store</span>
            </a>
          </div>
        </Section>
      </div>
    </div>
  );
};

export default Coin;
