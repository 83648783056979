import axios from 'axios';
import { useEffect, useState } from 'react';

import CountUp from '../../components/CountUp';
import { Section } from '../../components/Section';

const WORDS_GENERATED = 40000000000;

const Status = () => {
  const [count, setCount] = useState({});

  useEffect(() => {
    axios
      .get('https://gateway.schoolhack.ai/user/auth/estimated/counts')
      .then(response => {
        setCount(response.data);
      })
      .catch(ex => {
        console.log(ex);
      });
  }, []);

  return (
    <Section className="flex max-w-[90rem] flex-col gap-[3.12rem] pt-[3rem] lg:pt-0">
      <div className="flex w-full flex-wrap items-center gap-[1.75rem] lg:items-end lg:justify-between">
        <div className="flex w-full flex-col gap-[1rem] border-b border-[#DBDBDB] border-opacity-30 pb-[2rem] lg:w-fit lg:border-0 lg:pt-0">
          <p className="usersStroke text-[5.33906rem] font-bold leading-[1] tracking-[0.16244rem] lg:text-[8.122rem] lg:leading-[0.84]">
            Users
          </p>
          <CountUp
            className="text-[5.23375rem] font-bold leading-[1] tracking-[0.16244rem] text-accent lg:text-[8.122rem] lg:leading-[0.84]"
            number={count?.users || 0}
          />
        </div>
        <p className="text-[3rem] leading-[1] tracking-[0.0945rem] lg:mb-[2.5rem] lg:text-[5.335rem]">
          <span className="text-[3rem] leading-[1] tracking-[0.0945rem] text-accent lg:text-[5.335rem]">
            #1
          </span>
          Education App
        </p>
      </div>
      <div className="flex w-full flex-col flex-wrap items-start lg:flex-row lg:items-center lg:justify-between lg:gap-[1.75rem]">
        <p className="flex w-fit text-[1.5rem] leading-[1.388] tracking-[-0.0345rem] lg:text-[3.44744rem] lg:font-normal">
          Words Generated&nbsp;
          <CountUp number={WORDS_GENERATED} shouldFormatWithComma={false} />
          {/*<CountUp number={count?.prompts || 0} />*/}
          {/*{count?.words || 0}*/}
        </p>
        <span className="hidden w-fit text-[3.44744rem] leading-[1.388] tracking-[-0.0345rem] lg:block">
          |
        </span>
        <p className="w-fit text-[1.5rem] leading-[1.388] tracking-[-0.0345rem] lg:text-[3.44744rem] lg:font-normal">
          Queries Made&nbsp;
          <CountUp number={count?.prompts || 0} shouldFormatWithComma={false} />
        </p>
      </div>
    </Section>
  );
};

export default Status;
