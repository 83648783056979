export const CookiesTable = ({ rows }) => {
  return (
    <div className="overflow-x-auto my-10">
      <div className="border-solid border-black border min-w-[1000px] ">
        <div className="flex border-b font-bold border-b-solid border-b-black">
          <h3 className="flex w-[20%] flex-shrink-0 text-[20px] md:text-[24px] leading-[28px] md:leading-[32px] bg-gray-400  pt-4 pb-2 px-2 border-r font-bold border-r-solid border-r-black">
            Cookie
          </h3>
          <h3 className="flex w-[10%] flex-shrink-0 text-[20px] md:text-[24px] leading-[28px] md:leading-[32px] bg-gray-400 border-r font-bold border-r-solid border-r-black pt-4 pb-2 px-2">
            Name
          </h3>
          <h3 className="flex w-[30%] flex-shrink-0 text-[20px] md:text-[24px] leading-[28px] md:leading-[32px] bg-gray-400 border-r border-r-solid border-r-black font-bold pt-4 pb-2 px-2">
            Purpose
          </h3>
          <h3 className="flex w-[15%] flex-shrink-0 text-[20px] md:text-[24px] leading-[28px] md:leading-[32px] bg-gray-400 border-r border-r-solid border-r-black font-bold pt-4 pb-2 px-2">
            Persistent/ Session
          </h3>
          <h3 className="flex w-[25%] flex-shrink-0 text-[20px] md:text-[24px] leading-[28px] md:leading-[32px] bg-gray-400 font-bold pt-4 pb-2 px-2">
            More information
          </h3>
        </div>

        {rows.map((row, index) => (
          <div
            key={row + index}
            className="flex border-b border-b-solid border-b-black"
          >
            <p className="flex w-[20%] flex-shrink-0 text-[20px] md:text-[24px] leading-[28px] md:leading-[32px] pt-4 pb-2 px-2 border-r border-r-solid border-r-black">
              {row[0]}
            </p>
            <p className="flex w-[10%] flex-shrink-0 text-[20px] md:text-[24px] leading-[28px] md:leading-[32px] border-r border-r-solid border-r-black pt-4 pb-2 px-2">
              {row[1]}
            </p>
            <p className="flex w-[30%] flex-shrink-0 text-[20px] md:text-[24px] leading-[28px] md:leading-[32px] border-r border-r-solid border-r-black pt-4 pb-2 px-2">
              {row[2]}
            </p>
            <p className="flex w-[15%] flex-shrink-0 text-[20px] md:text-[24px] leading-[28px] md:leading-[32px] border-r border-r-solid border-r-black pt-4 pb-2 px-2">
              {row[3]}
            </p>
            <p className="flex overflow-hidden max-w-[25%] w-[25%] flex-shrink-0 text-[20px] md:text-[24px] leading-[28px] md:leading-[32px] pt-4 pb-2 px-2">
              {row[4]}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
