export const TOOLS_LINKS = [
  {
    name: "AI Essay Support",
    url: "https://app.schoolhack.ai/essay",
  },
  {
    name: "Plagiarism Checker",
    url: "https://app.schoolhack.ai/check-plagiarism",
  },
  {
    name: "Paraphrasing Tool",
    url: "https://app.schoolhack.ai/paraphrase",
  },
  {
    name: "AI Student Groups",
    url: "https://app.schoolhack.ai/group",
  },
  {
    name: "Ask anything",
    url: "https://app.schoolhack.ai/",
  },
];
