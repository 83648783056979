
import { Section } from '../../components/Section';
import handAndPhoneImage from './../../assets/images/hand with phone.png'

const AskAnything = () => {
  return (
    <div>
      <Section className="!px-0 mb-[6.23rem]">
        <h2 className="clamp text-white font-extrabold whitespace-nowrap">
          <span className="text-accent">Ask</span> Anything
        </h2>
      </Section>

      <div className="bg-white h-[28rem] lg:h-[35rem] relative">
        <img src={handAndPhoneImage} alt="Hand holding phone" className='bottom-0 max-h-[37rem] lg:max-h-max left-1/2 -translate-x-1/2 absolute' />
      </div>
    </div>

  );
};

export default AskAnything;
