import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Layout from './components/Layout';
import ScrollToTop from './components/ScrollToTop';
import BlogDetails from './pages/BlogDetails';
import Blogs from './pages/Blogs';
import Contactus from './pages/Contactus';
import CookiesPolicy from './pages/CookiesPolicy';
import Main from './pages/Main';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsCondition from './pages/TermsCondition';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/cookies-policy" element={<CookiesPolicy />} />
          <Route path="/terms-condition" element={<TermsCondition />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/blogs/:link" element={<BlogDetails />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
