import { useEffect } from "react";

export const useScroll = (isEnabled) => {
  useEffect(() => {
    if (isEnabled) {
      document.body.classList.remove("noScroll");
    } else {
      document.body.classList.add("noScroll");
    }
  }, [isEnabled]);
};
