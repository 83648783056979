import { PrivacyBlock } from "../components/PrivacyBlock";
import { PrivacyList } from "../components/PrivacyList";

const PrivacyPolicy = () => {
  return (
    <div className="bg-white relative z-[2] py-16 md:pt-[116px] md:pb-[163px] px-4">
      <div className="max-w-6xl 2xl:max-w-[1440px] mx-auto">
        <h1 className="text-black uppercase text-center mb-[50px] md:mb-[77px] text-[32px] md:text-[40px] leading-[47px] font-bold">
          School Hack PRIVACY NOTICE
        </h1>

        <PrivacyBlock
          title="Who we are?"
          paragraphs={[
            'We are School Hack ("Company").',
            "The Company is a social educational platform driven by artificial intelligence.",
            "We are committed to protecting the privacy of our customers and want to provide a safe and secure service where we deal with your personal information. This means information that identifies you personally such as your name, photo or contact details or data that can be linked with such information in order to identify you. We have, therefore, put in place measure to prevent the unauthorised or unnecessary disclosure of your personal information.",
          ]}
        />

        <PrivacyList
          title="Quick Guide to Contents"
          points={[
            "What personal information do we collect about you?",
            "How do we use your personal information?",
            "How long do we keep your personal information for?",
            "Who do we share your personal information with?",
            "What happens if you do not provide us with the information we request?",
            "Do we make automated decisions concerning you?",
            "Do we transfer your personal information outside the UAE?",
            "What are your rights?",
            "How do we contact you?",
            "How do you contact us?",
          ]}
        />

        <div className="mb-10">
          <h3 className="flex text-[20px] md:text-[24px] leading-[28px] md:leading-[32px] font-bold pt-4 pb-2 px-2">
            What personal information may we collect about you?
          </h3>

          <p className="px-2 pt-3 text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
            We collect and process your information in the following ways:
          </p>
          <p className="px-2 text-[16px] font-bold md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
            Information you give us
          </p>

          <ul className="!list-disc !pl-10">
            <li className=" px-2 text-[16px] md:text-[20px] leading-[28px] md:leading-[32px] mb-2">
              Name
            </li>
            <li className="px-2   text-[16px] md:text-[20px] leading-[28px] md:leading-[32px] mb-2">
              Gender
            </li>
            <li className="px-2  text-[16px] md:text-[20px] leading-[28px] md:leading-[32px] mb-2">
              Date of birth
            </li>
            <li className="px-2  text-[16px] md:text-[20px] leading-[28px] md:leading-[32px] mb-2">
              Photograph, facial scan or equivalent visual identification
            </li>
            <li className="px-2  text-[16px] md:text-[20px] leading-[28px] md:leading-[32px] mb-2">
              Email address
            </li>
            <li className="px-2  text-[16px] md:text-[20px] leading-[28px] md:leading-[32px] mb-2">
              Telephone number
            </li>
          </ul>

          <p className="px-2 text-[16px] font-bold md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
            Information we collect automatically
          </p>

          <ul className="!list-disc !pl-10">
            <li className="px-2  text-[16px] md:text-[20px] leading-[28px] md:leading-[32px] mb-2">
              Any information you provide when interacting with us, including
              via our customer care team
            </li>
            <li className="px-2  text-[16px] md:text-[20px] leading-[28px] md:leading-[32px] mb-2">
              Name
            </li>
            <li className="px-2  text-[16px] md:text-[20px] leading-[28px] md:leading-[32px] mb-2">
              Email address
            </li>
          </ul>

          <p className="px-2 text-[16px] font-bold md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
            Information we receive from third parties
          </p>

          <ul className="!list-disc !pl-10">
            <li className="px-2  text-[16px] md:text-[20px] leading-[28px] md:leading-[32px] mb-2">
              Name
            </li>
            <li className="px-2  text-[16px] md:text-[20px] leading-[28px] md:leading-[32px] mb-2">
              Email address
            </li>
          </ul>
        </div>

        <PrivacyBlock
          title="How do we use your personal information?"
          paragraphs={[
            "We utilize your personal information for various purposes to offer an enhanced, personalized experience and seamless service delivery. Primarily, we use the information to personalize your content experience which ensures we deliver relevant, helpful content that suits your interests and needs. For instance, we send out email updates that detail actions performed on our application such as payments, transfers, and file processing. These updates keep you informed about the activity that involves your account, ensuring transparency and security.",
            "In addition, we use your personal information such as your name or username and photograph (if provided) to facilitate social interactions within our platform, such as in direct and group messaging. Creating a unique, personal profile for you enables us to grow and nurture a community within our application, promoting a sense of belonging and engagement among our users.",
            "Moreover, your information is integral to our customer service operations. It allows us to respond accurately and swiftly to your inquiries, requests, or issues, providing you with an efficient and effective service.",
            "Further, your data helps us in improving our application functionality and performance by understanding your interactions, preferences, and behaviors. This information encourages us to evolve and optimize our services continually. We also use your data to tailor more relevant promotional material based on inferred interests, contributing to a more engaging and valuable experience on our platform.",
            "Additionally, for security and legal obligations, we may utilize your personal information to adhere to regulatory requirements, prevent fraudulent activity, enforce our agreements, resolve disputes, and protect the rights and property of our application and its users.",
            "Additionally, for security and legal obligations, we may utilize your personal information to adhere to regulatory requirements, prevent fraudulent activity, enforce our agreements, resolve disputes, and protect the rights and property of our application and its users.",
            "We also deploy your personal information to further our marketing initiatives. By understanding your preferences, activities, and general behavior on our platform, we are able to develop and implement targeted marketing and advertising campaigns. This includes presenting you with promotional content and special offers that are aligned with your expressed and inferred interests.",
            "Furthermore, we may relay pertinent updates, new product or service announcements, and surveys via your provided contact information, customized to match your specific needs and preferences.",
            "However, we prioritize your privacy and choice in this regard. You will always have the option to opt-out from receiving such marketing communications from us.",
          ]}
        />

        <PrivacyBlock
          title="How long do we keep your personal information for?"
          paragraphs={[
            "We keep your information for the following periods:",
            "We retain your personal information for as long as it is necessary and relevant for our operations. In general, we keep your details while your account is active or as needed to provide you with our services. However, in certain cases, we may need to retain personal data for a longer period where required by law, regulation, or to maintain compliance with statutory obligations.",
            "While you are a user of our platform, we ensure that your data is kept up to-date and accurate for the purpose it was intended for. If you decide to terminate your account or request that we no longer use your information to provide you with our services, we will adhere to your request and execute the deletion of your personal data from our systems in compliance with appropriate laws and regulations.",
            "It is important to note that we may retain and use your information as necessary to comply with our legal obligations, resolve disputes, enforce our agreements, and as permitted by applicable legal standards. Rest assured, all your data is managed with utmost care and rigorous protocols are in place to safeguard its security and privacy.",
            "We generally retain your personal information for a period of five years from the conclusion of your relationship with our platform or from the last contact we had with you, unless national law requires a longer retention period. This period is set due to statutory obligations under financial and data protection regulations, which demand the securing and preserving of customer data for a specified period.",
            "Post this retention period, or once the purpose for holding your data is no longer applicable, or if you request the deletion of your data, we will safely dispose of your personal information in accordance with our data deletion policies to maintain your privacy and security. ",
            "It is important to reiterate that longer retention periods may be applicable if we are required to hold your personal data due to legal, regulatory, or accounting requirements. We are committed to ensuring that your information is managed according to the strictest data security standards.",
          ]}
        />

        <PrivacyBlock
          title="Whom do we share your personal information with?"
          paragraphs={[
            "We share your personal information with trusted third parties where we have retained them to provide services that you have requested, such as payment providers to facilitate your deposit of funds into your account. These third parties comply with similar and equally stringent undertakings of privacy and confidentiality.",
            "We share your personal information with third parties who perform functions on our behalf and who also provide services to us, such as professional advisors, IT consultants carrying out testing and development work on our business technology systems, research and mailing houses and function co-ordinators. These third parties comply with similar and equally stringent undertakings of privacy and confidentiality.",
            "We share your personal information with our other group and affiliated entities and institutions for internal reasons, primarily for business and operational purposes. ",
            <span>
              Where required we share your personal information with third
              parties to comply with a legal obligation; when we believe in good
              faith that an applicable law requires it; at the request of
              governmental authorities conducting an investigation; to verify or
              enforce our Terms of Use{" "}
              <a
                target="_blank"
                href="https://www.schoolhack.ai/terms-condition"
                className="font-bold underline"
              >
                https://www.schoolhack.ai/terms-condition
              </a>{" "}
              or other applicable policies; to detect and protect against fraud,
              or any technical or security vulnerabilities; to respond to an
              emergency; or otherwise to protect the rights, property, safety,
              or security of third parties, visitors to the our website, our
              business or the public.
            </span>,
          ]}
        />

        <PrivacyBlock
          title="What happens if you do not provide us with the information we request or ask that we stop processing your information?"
          paragraphs={[
            "If you do not provide the personal information necessary, or withdraw your consent for the processing of your personal information, where this information is necessary for us to provide services or information to you, we will not be able to provide these services or information to you.",
          ]}
        />

        <PrivacyBlock
          title="Do we make automated decisions concerning you?"
          paragraphs={[
            "Yes, we utilize automated decision-making processes in certain scenarios to offer a more efficient, objective, and swift service. These systems use algorithms and computational logic to analyze your personal data and make decisions based on predefined rules. This process could involve profiling, which helps us understand your needs, preferences, or behaviors better, or risk assessments to prevent fraudulent activity.",
            "For example, we may automatically decide which promotional materials to send you based on your usage patterns and interests. Likewise, in our fraud prevention efforts, we may use automated systems to flag unusually high transaction volumes or suspicious activities.",
            "However, we always ensure that these processes don't negatively impact your rights. We put significant safeguards in place to protect your interests, including regular checks of the systems to avoid errors, bias, or discrimination. You also have the option to challenge and request a review of decisions made solely based on automated processing if it considerably affects you or produces legal effects",
          ]}
        />

        <PrivacyBlock
          title="Do we use Cookies to collect personal data on you?"
          paragraphs={[
            <span>
              To provide better service to you on our websites, we use cookies
              to collect your personal data when you browse. See our cookie
              policy here{" "}
              <a
                target="_blank"
                href="https://www.schoolhack.ai/privacy-policy"
                className="font-bold underline"
              >
                https://www.schoolhack.ai/privacy-policy
              </a>{" "}
              for more details.
            </span>,
          ]}
        />

        <PrivacyBlock
          title="Do we transfer your personal information outside the UAE?"
          paragraphs={[
            "Yes, whenever we transfer personal data out of the United Arab Emirates, we implement several safeguarding measures",
            "For instance, we ensure that the data transfer is conducted securely, using encryption and secure servers. Moreover, we maintain strict data protection principles and data minimization practices to limit the amount of personal information transferred, and we only share necessary details relevant to the service being provided.",
            "We also vet third-party recipients rigorously to ensure that they comply with stringent data protection standards and legislation similar to those prevailing in the UAE. They must provide adequate safeguards and demonstrate a proven track record of managing personal data securely and effectively.",
            "Further, we insist on confidentiality agreements and adhere to contractual clauses that bind these third parties to use your personal data solely for the agreed purpose and to afford it the same level of protection as we do. In addition, these parties are required to notify us in the event of any data breach, and we, in turn, will inform you.",
            "Through regular auditing, we consistently monitor and review these systems, practices, and third parties to ensure your data's ongoing security. ",
            "Please note that these measures are designed to ensure that your personal data is handled in a manner consistent with the data protection laws of UAE, even when processed outside our jurisdiction",
          ]}
        />

        <PrivacyBlock
          title="What are your rights?"
          paragraphs={[
            "By law, you have a number of rights when it comes to your personal information. Further information and advice about your rights can be obtained from the UAE data protection regulator.",
          ]}
          className="mb-0"
        />

        <div className="mb-10">
          <div className="flex">
            <h3 className="flex w-[40%] mb-2  border-solid border-black text-[20px] md:text-[24px] leading-[28px] md:leading-[32px] font-bold pt-4 pb-2 px-2">
              Rights
            </h3>
            <h3 className="flex justify-start items-center w-[60%] text-[20px] md:text-[24px] leading-[28px] md:leading-[32px] mb-2 font-bold  pt-4 pb-2 px-2">
              What does this mean?
            </h3>
          </div>
          <div className="flex border-b border-solid border-black mb-3">
            <p className="px-2 w-[40%]  border-solid border-black text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px]">
              The right to object to processing
            </p>
            <p className="px-2 w-[60%] text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
              You have the right to object to certain types of processing,
              including processing for direct marketing (i.e. if you no longer
              want to be contacted with potential opportunities).
            </p>
          </div>

          <div className="flex border-b border-solid border-black mb-3">
            <p className="px-2 w-[40%]  border-solid border-black text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px]">
              The right to be informed
            </p>
            <p className="px-2 w-[60%] text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
              You have the right to be provided with clear, transparent and
              easily understandable information about how we use your
              information and your rights. This is why we’re providing you with
              the information in this notice.
            </p>
          </div>

          <div className="flex border-b border-solid border-black mb-3">
            <p className="px-2 w-[40%]  border-solid border-black text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px]">
              The right of access
            </p>
            <p className="px-2 w-[60%] text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
              You have the right to obtain access to your information (if we’re
              processing it), and certain other information (similar to that
              provided in this Privacy Notice). This is so you’re aware and can
              check that we’re using your information in accordance with data
              protection law.
            </p>
          </div>

          <div className="flex border-b border-solid border-black mb-3">
            <p className="px-2 w-[40%]  border-solid border-black text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px]">
              The right to rectification
            </p>
            <p className="px-2 w-[60%] text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
              You are entitled to have your information corrected if it’s
              inaccurate or incomplete.
            </p>
          </div>

          <div className="flex border-b border-solid border-black mb-3">
            <p className="px-2 w-[40%]  border-solid border-black text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px]">
              The right to erasure
            </p>
            <p className="px-2 w-[60%] text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
              This is also known as ‘the right to be forgotten’ and, in simple
              terms, enables you to request the deletion or removal of your
              information where there’s no compelling reason for us to keep
              using it. This is not a general right to erasure; there are
              exceptions.
            </p>
          </div>

          <div className="flex border-b border-solid border-black mb-3">
            <p className="px-2 w-[40%]  border-solid border-black text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px]">
              The right to restrict processing
            </p>
            <p className="px-2 w-[60%] text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
              You have rights to ‘block’ or suppress further use of your
              information. When processing is restricted, we can still store
              your information, but may not use it further. We keep lists of
              people who have asked for further use of their information to be
              ‘blocked’ to make sure the restriction is respected in future.
            </p>
          </div>

          <div className="flex border-b border-solid border-black mb-3">
            <p className="px-2 w-[40%]  border-solid border-black text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px]">
              The right to data portability
            </p>
            <p className="px-2 w-[60%] text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
              You have rights to obtain and reuse your personal information for
              your own purposes across different services. For example, if you
              decide to switch to a new provider, this enables you to move, copy
              or transfer your information easily between our IT systems and
              theirs safely and securely, without affecting its usability.
            </p>
          </div>

          <div className="flex border-b border-solid border-black mb-3">
            <p className="px-2 w-[40%]  border-solid border-black text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px]">
              The right to lodge a complaint
            </p>
            <p className="px-2 w-[60%] text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
              You have the right to lodge a complaint about the way we handle or
              process your personal information with your national data
              protection regulator and/or the GCGRA.
            </p>
          </div>

          <div className="flex mb-3">
            <p className="px-2 w-[40%]  border-solid border-black text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px]">
              The right to withdraw consent
            </p>
            <p className="px-2 w-[60%] text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
              If you have given your consent to anything we do with your
              personal information, you have the right to withdraw your consent
              at any time (although if you do so, it does not mean that anything
              we have done with your personal information with your consent up
              to that point is unlawful). This includes your right to withdraw
              consent to us using your personal information for marketing
              purposes.
            </p>
          </div>
        </div>
        <div className="mb-10">
          <p className="px-2  text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
            We usually act on requests and provide information free of charge,
            but may charge a reasonable fee to cover our administrative costs of
            providing the information for: baseless or excessive/repeated
            requests, or further copies of the same information.
          </p>

          {/* <ul className="!list-disc !pl-10">
            <li className="px-2 text-[16px] md:text-[20px] leading-[28px] md:leading-[32px] mb-2"></li>

            <li className="px-2 text-[16px] md:text-[20px] leading-[28px] md:leading-[32px] mb-2"></li>
          </ul> */}

          <p className="px-2  text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
            Alternatively, we may be entitled to refuse to act on the request.
          </p>

          <p className="px-2 text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
            Please consider your request responsibly before submitting it. We’ll
            respond as soon as we can. Generally, this will be within one month
            from when we receive your request but, if the request is going to
            take longer to deal with, we’ll come back to you and let you know.
          </p>
        </div>

        <PrivacyBlock
          title="How will we contact you?"
          paragraphs={[
            "We may contact you by phone, email or social media. If you prefer a particular contact means over another please just let us know.",
          ]}
        />

        <PrivacyBlock
          title="How can you contact us?"
          paragraphs={[
            <span>
              If you have any enquires you can contact us at:{" "}
              <a
                target="_blank"
                href="mailto:info@schoolhack.ai"
                className="font-bold underline"
              >
                info@schoolhack.ai
              </a>
            </span>,
          ]}
        />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
