import { useEffect, useState } from 'react';

import Footer from './Footer';
import Header from './Header';

const Layout = props => {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      setIsScrolling(scrollTop > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="main-bg min-h-screen">
        <Header isScrolling={isScrolling} />
        <div className='pt-[100px]'>
          {props.children}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
