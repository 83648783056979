export function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const getTextSize = size => {
  switch (size) {
    case 1:
      return 'text-[22px] sm:text-[26px]';
    case 2:
      return 'text-[20px] sm:text-[24px]';
    case 3:
      return 'text-[18px] sm:text-[22px]';
    case 4:
      return 'text-[16px] sm:text-[20px]';
    default:
      return 'text-[22px] sm:text-[26px]';
  }
};

export function classNames(...classes) {
  const finalClasses = [];
  classes.forEach(classEntry => {
    if (typeof classEntry === 'string') {
      finalClasses.push(classEntry);
    }
  });
  return finalClasses.join(' ');
}
export default classNames;

export function formatNumber(num) {
  if (num == null) {
    return '-';
  } else if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1) + 'B';
  } else if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  } else {
    return num.toString();
  }
}
