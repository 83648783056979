import { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { TOOLS_LINKS } from '../constatnts';
import { useOutsideClick } from '../hooks/useOutsideClick';
import { useScroll } from '../hooks/useScroll';
import { ChevronIcon, MenuIcon } from './icons';

import logoIcon from './../assets/icons/LogoAvatar.svg';

const PAGES_WITH_BLACK_HEADER = ['/contactus', '/terms-condition', '/privacy-policy', '/cookies-policy', '/blogs/math-ai-homework-solver/', '/blogs/ai-homework-solver/', '/blogs/chemistry-ai-homework-solver/'];

const Header = ({ isScrolling }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const contextMenuRef = useRef(null);
  const burgerButtonRef = useRef(null);

  const { pathname } = useLocation();
  useScroll(!isMobileMenuOpen);
  useOutsideClick(isContextMenuOpen ? contextMenuRef : null, () => {
    setIsContextMenuOpen(false);
  });

  return (
    <header
      className={twMerge(
        'fixed top-0 z-[20] flex h-[100px] w-full items-center justify-center',
        (isScrolling || PAGES_WITH_BLACK_HEADER.includes(pathname)) &&
        'bg-[#101010]',
      )}
    >
      <nav
        className={
          'm-auto flex h-[100px] w-full max-w-[94.5rem] items-center justify-between px-8 md:px-12 lg:px-5'
        }
      >
        <Link
          onClick={() => setIsMobileMenuOpen(false)}
          to="/"
          title="SchoolHack"
        >
          <img src={logoIcon} alt="Logo" />
        </Link>

        <div className="relative hidden items-center justify-end gap-10 md:flex lg:gap-[84px]">
          <div ref={contextMenuRef}>
            <button
              className="relative flex items-center gap-2 text-white hover:text-accent"
              onClick={() => setIsContextMenuOpen(prev => !prev)}
            >
              <span className=" text-[16px] transition sm:text-[20px] xl:text-[23px]">
                Tools
              </span>
              <div
                className={twMerge(
                  'transition duration-75',
                  isContextMenuOpen ? 'rotate-0' : '-rotate-90',
                )}
              >
                <ChevronIcon />
              </div>
            </button>

            <ul
              className={twMerge(
                'bgBlurLighter absolute -left-[120px] top-[45px] flex flex-col items-center gap-y-3 rounded-[14px] border-2 border-[#767676] p-4 text-[20px] text-white transition-all duration-100',
                isContextMenuOpen
                  ? 'pointer-events-auto opacity-100'
                  : 'pointer-events-none opacity-0',
              )}
            >
              {TOOLS_LINKS.map(link => (
                <li className="w-full whitespace-nowrap" key={link.url}>
                  <a
                    className="transition hover:text-accent"
                    target="blank"
                    onClick={() => setIsContextMenuOpen(false)}
                    href={link.url}
                  >
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <Link
            to="/blogs"
            className=" flex cursor-pointer items-center whitespace-nowrap  text-[16px] font-[400] leading-[31px] tracking-wide text-white transition hover:text-accent sm:text-[20px] xl:text-[23px]"
          >
            Blogs
          </Link>
          <a
            target="blank"
            href="https://app.schoolhack.ai/auth/register"
            className=" flex cursor-pointer items-center whitespace-nowrap  text-[16px] font-[400] leading-[31px] tracking-wide text-white transition hover:text-accent sm:text-[20px] xl:text-[23px]"
          >
            Start Now
          </a>
        </div>

        <button
          title="menu"
          className="outline-none md:hidden"
          ref={burgerButtonRef}
          onClick={() => setIsMobileMenuOpen(prev => !prev)}
        >
          <MenuIcon className="w-[30px] fill-white text-white " />
        </button>

        <div
          className={twMerge(
            isMobileMenuOpen
              ? 'h-[calc(100vh-100px)] pt-10 '
              : 'h-[0] overflow-hidden pt-0',
            'bgBlur absolute left-0 right-0 top-[100px] flex w-full flex-col items-center justify-start gap-4 transition-all duration-75 md:hidden',
          )}
        >
          {TOOLS_LINKS.map(link => (
            <a
              key={link.url}
              className="text-[18px] font-medium text-white transition hover:text-accent"
              target="blank"
              onClick={() => setIsMobileMenuOpen(false)}
              href={link.url}
            >
              {link.name}
            </a>
          ))}

          <Link
            onClick={() => setIsMobileMenuOpen(false)}
            to="/blogs"
            className="text-[18px] font-medium text-white transition hover:text-accent"
          >
            Blogs
          </Link>

          <a
            onClick={() => setIsMobileMenuOpen(false)}
            target="blank"
            href="https://app.schoolhack.ai/auth/register/"
            className="text-[18px] font-medium text-white transition hover:text-accent"
          >
            Start Now
          </a>
        </div>
      </nav>
    </header>
  );
};

export default Header;
