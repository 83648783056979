import blog1 from "../assets/images/webp/preview3.webp";
import blog2 from "../assets/images/webp/preview2.webp";
import blog3 from "../assets/images/webp/preview1.webp";
import avatar from "../assets/icons/LogoAvatar.svg";

const BLOGS = [
  {
    img: blog1,
    link: "math-ai-homework-solver",
    title:
      "Math AI Homework Assistance: Fostering Learning and Problem-Solving Skills",
    avatar: avatar,
    name: "SCHOOLHACK",
    date: "June 24, 2023",
    shares: "1k",
    description:
      "Mathematical subjects have always been challenging to all students. Math homework, from basic arithmetic all the way to calculus can lea...",
  },
  {
    img: blog2,
    link: "ai-homework-solver",
    title:
      "Bid farewell to the challenges of homework stress with the AI Homework Assistance App from SchoolHack!",
    avatar: avatar,
    name: "SCHOOLHACK",
    date: "June 24, 2023",
    shares: "1k",
    description:
      "Homework stress has become an alarming issue among students, leading to negative impacts on their mental health and academic performance...",
  },
  {
    img: blog3,
    link: "chemistry-ai-homework-solver",
    title:
      "Revolutionising Chemistry Education: Introducing the Chemistry AI Homework Assistance",
    avatar: avatar,
    name: "SCHOOLHACK",
    date: "July 8, 2023",
    shares: "1k",
    description:
      "The Chemistry AI app explains these topics in detail: properties of matter, chemical reactions, and the composition of substances...",
  },
];

export default BLOGS;
