import { twMerge } from "tailwind-merge";

export const Section = ({ className, children }) => {
  return (
    <section
      className={twMerge(
        "max-w-[94.5rem] m-auto px-8 md:px-12 lg:px-5 text-white",
        className
      )}
    >
      {children}
    </section>
  );
};
