import { Link } from 'react-router-dom';

import logoIcon from './../assets/icons/LogoAvatar.svg';
import emptySHImage from './../assets/images/emptySH.png';
import { Section } from './Section';
import Socials from './Socials';

const IS_PRODUCTION = process.env.REACT_APP_PRODUCTION;

const KEYFEATURES = [
  { name: 'Paraphrasing Tool', link: 'paraphrase' },
  { name: 'Plagiarism Checker', link: 'check-plagiarism' },
  { name: 'AI Student Groups', link: 'group' },
  { name: 'Knowledge', link: 'knowledge' },
];

const Footer = () => {
  return (
    <Section>
      <footer className='mt-[1.56rem] pb-[2.49rem] md:pb-[4.05rem] md:mt-[130px] border-t pt-[1rem] md:border-0 border-[#6d6d6d]'>
        <div className="relative m-auto mx-auto flex flex-col md:flex-row justify-between gap-[20px] text-white md:gap-[40px] lg:gap-[60px] xl:gap-x-[80px] 2xl:gap-[200px]">
          <div className="w-[100%] max-w-[100%] md:w-auto md:max-w-[30%]">
            <img src={logoIcon} alt="Logo" />
            <p className="mt-[1.83rem] text-[1rem] font-[400] leading-[1.875rem] lg:min-w-[20rem]">
              The Doc chat feature allows user to chat with uploaded documents.
              Users are able to upload DOCX, EPUB and TXT files. The document
              manager of the feature allows user to keep their files and organize
              them into folders
            </p>

            <div className='text-[#8E8E8E] hidden md:block mt-[1.69rem] w-content border-t pt-[1.69rem] border-[#DBDBDB]/30 leading-[187.5%]'>
              ©2024 SchoolHack. All rights reserved
            </div>
          </div>

          <div className='w-full flex flex-col'>
            <div className='flex flex-col md:flex-row justify-between'>
              <div className='flex justify-between items-center md:items-start'>
                <div className="flex flex-col items-start">
                  <h3 className="mb-[0.7rem] md:mb-[16px] text-accent md:text-white md:mt-[25px] whitespace-nowrap text-[1.25rem] font-bold leading-[1.875rem]">
                    Key Features
                  </h3>
                  {KEYFEATURES.map((el, id) => (
                    <a
                      className="whitespace-nowrap text-[14px] leading-[1.875rem] transition hover:text-accent sm:text-[1rem]"
                      key={id}
                      target="blank"
                      href={
                        IS_PRODUCTION
                          ? `https://app.schoolhack.ai/${el.link}`
                          : `https://dev.schoolhack.ai/${el.link}`
                      }
                    >
                      {el.name}
                    </a>
                  ))}
                </div>
                <img src={emptySHImage} alt='Schoolhack' className='w-[12rem] md:hidden' />
              </div>

              <div className="flex flex-col items-start">
                <h3 className="mb-[0.7rem] md:mb-[16px] text-accent md:text-white md:mt-[25px] whitespace-nowrap text-[1.25rem] font-bold leading-[1.875rem]">
                  Contact Us
                </h3>
                <Link
                  className="text-[1rem] leading-[1.875rem] transition hover:text-accent"
                  to="/contactus"
                >
                  Our Support
                </Link>
                <Link
                  to="/contactus"
                  className="text-[1rem] leading-[1.875rem] transition hover:text-accent"
                >
                  Email
                </Link>
                <a
                  className="whitespace-nowrap text-[1rem] leading-[1.875rem] transition hover:text-accent"
                  href="https://discord.gg/schoolhack"
                  target="blank"
                >
                  Discord Community
                </a>
              </div>

              <div className="flex shrink-0 flex-col items-start text-[1rem] leading-[1.875rem]">
                <h3 className="mb-[0.7rem] md:mb-[16px] text-accent md:text-white md:mt-[25px] whitespace-nowrap text-[1.25rem] font-bold leading-[1.875rem]">
                  Terms
                </h3>
                <Link
                  className="break-keep text-[1rem] leading-[1.875rem] transition hover:text-accent"
                  to="/terms-condition"
                >
                  Terms & Conditions
                </Link>
                <Link
                  to="/privacy-policy"
                  className="break-keep text-[1rem] leading-[1.875rem] transition hover:text-accent"
                >
                  Privacy Policy
                </Link>
                <Link
                  to="/cookies-policy"
                  className="break-keep text-[1rem] leading-[1.875rem] transition hover:text-accent"
                >
                  Cookies Policy
                </Link>
              </div>
            </div>
            <div className='text-[#8E8E8E] mt-[1rem] md:mt-[1.69rem] w-content border-t pt-[1rem] md:pt-[1.69rem] border-[#DBDBDB]/30 leading-[187.5%]'>
              <Socials />
            </div>
          </div>
        </div>


        <div className='text-[#8E8E8E]  md:hidden mt-[1rem] md:mt-[1.69rem] w-content border-t pt-[1rem] md:pt-[1.69rem] border-[#DBDBDB]/30 leading-[187.5%]'>
          ©2024 SchoolHack. All rights reserved
        </div>
      </footer>
    </Section>
  );
};

export default Footer;
