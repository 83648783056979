import { CookiesTable } from "../components/CookiesTable";
import { PrivacyBlock } from "../components/PrivacyBlock";
import { PrivacyList } from "../components/PrivacyList";

const CookiesPolicy = () => {
  return (
    <div className="bg-white relative z-[2] py-16 md:pt-[116px] md:pb-[163px] px-4">
      <div className="max-w-6xl 2xl:max-w-[1440px] mx-auto">
        <h1 className="text-black uppercase text-center mb-[50px] md:mb-[77px] text-[32px] md:text-[40px] leading-[47px] font-bold">
          School Hack COOKIES POLICY
        </h1>

        <p className="block px-2 pt-3 text-[16px] leading-[28px] underline md:text-[20px] md:leading-[32px]">
          Legal note:
        </p>

        <p className="mb-2 block px-2 pt-3 text-[16px] leading-[28px] md:text-[20px] md:leading-[32px]">
          The Company will need to implement a method on its website for users
          to be able to choose which cookies they are happy with and to disable
          any of the non ‘strictly necessary’ cookies which they do not agree
          with (see below for more advice on ‘strictly necessary’ cookies).
          Also, the Company will need to consider what type of consent solution
          it is going to operate on the website. ADGM and DIFC data protection
          regulator guidance on cookies states that where you are using cookies
          (or allowing a third party to use cookies on your site).
        </p>

        <p className="px-2 underline pt-3 text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px]">
          Use of Cookies
        </p>

        <p className="px-2 pt-3 text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
          In order to make visiting our website attractive and to enable the use
          of certain functions, we use cookies in some areas. Cookies are text
          files containing small amounts of information, which are downloaded to
          your computer or mobile device by websites that you visit. They can
          improve your experience of using a website, for example, by
          remembering your preference settings and tracking your use of the
          website so that they can be improved to meet your needs. They can also
          help to ensure that adverts you see online are more relevant to you
          and your interests.
        </p>
        <p className="px-2 pt-3 text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
          All websites must get consent to send cookies to your computer or
          mobile device unless the cookies are strictly necessary to provide
          services to you.
        </p>
        <p className="px-2 pt-3 text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
          You can set your cookie preferences when you arrive at our website,or
          change them at any time{" "}
          <a
            target="_blank"
            href="http://www.google.com/policies/privacy/"
            className="text-blue-400 underline"
          >
            (http://www.google.com/policies/privacy/)
          </a>
          .
        </p>
        <p className="px-2 pt-3 text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
          Unless the cookie is a strictly necessary cookie, you can withdraw
          your consent to our cookies at any time even if you have previously
          consented. Although, please do remember that if you do not consent to
          our functionality cookies, parts of our website will not work. For
          example, you will not be able to watch our videos. For this reason we
          will occasionally send you prompts which allow you to consent to a
          cookie you have previously rejected.{" "}
        </p>
        <p className="px-2 pt-3 text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
          The tables below explain what cookies we use on our website, why we
          use them and whether they are strictly necessary or another type of
          cookie e.g. "functionality" or "performance" cookies. We also state in
          the tables whether a cookie is a "persistent" or "session" cookie. The
          difference is that:{" "}
        </p>

        <ul className="!list-disc !pl-10">
          <li className="px-2  text-[16px] md:text-[20px] leading-[28px] md:leading-[32px] mb-2">
            <strong>persistent cookies</strong> remain on your device between
            browsing sessions. They are activated each time you visit the
            Website that created that particular cookie. For example, where a
            "persistent cookie" is used on a Website to remember your log-in
            details, you will not need to enter those details each time you
            visit that Website; and
          </li>
          <li className="px-2  text-[16px] md:text-[20px] leading-[28px] md:leading-[32px] mb-2">
            <strong>session cookies</strong> allow website operators to link the
            actions of a user during a browser session. A browser session starts
            when you open the browser window and finishes when you close the
            browser window. Session cookies are created temporarily. Once you
            close the browser, all session cookies are deleted.
          </li>
        </ul>
        <p className="px-2 pt-3 text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
          Some of the cookies listed below are those set by our own website.
          However, we also have cookies on our website that are set by third
          parties – not us. For example, to enable us to have YouTube videos
          played through our website, YouTube places cookies on the website (for
          more information about how YouTube uses cookies and what information
          they collect, visit http://www.google.com/policies/privacy/).{" "}
        </p>
        <p className="px-2 underline pt-3 text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px]">
          Strictly necessary
        </p>
        <p className="px-2 pt-3 text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
          Strictly necessary cookies are essential to enable you to receive a
          service on a website. For example, cookies to operate online shopping
          baskets, do your internet banking or to comply with the law (e.g. such
          as to keep your information safe). We would not be able to operate our
          website without using the "strictly necessary" cookies listed below.
        </p>

        <CookiesTable
          rows={[
            [
              "Token",
              "",
              "These cookies are used to collect store authentication token for the user after login/signup, it is JWT Token critical for access to all protected apis",
              "",
              "N/A",
            ],
          ]}
        />

        <p className="px-2 underline pt-3 text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px]">
          Performance
        </p>
        <p className="px-2 pt-3 text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
          Performance cookies collect information about how visitors use a
          website, for instance, which pages visitors go to most often and if
          they get error messages from web pages. These cookies do not collect
          information that identifies a visitor. Any information collected by
          these cookies is anonymous. We only use such information to improve
          our website.
        </p>

        <CookiesTable
          rows={[
            [
              "_tccl_visitor",
              "",
              "These cookies are used and persistent Web Hosting Provider (GoDaddy) cookies to monitor website usage performance",
              "Persistent",
              <a
                target="_blank"
                href="https://ae.godaddy.com/agreements/privacy"
                className="text-blue-400 underline"
              >
                https://ae.godaddy.com...
              </a>,
            ],
          ]}
        />

        <p className="px-2 underline pt-3 text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px]">
          Functionality
        </p>
        <p className="px-2 pt-3 text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
          Functionality cookies allow the website to remember choices you make
          (such as your log in details) and customised preference settings (e.g.
          text size). They also enable enhanced, more personal features, e.g. a
          website may be able to provide you with local weather reports or
          traffic news by using a cookie to remember which region you are in.
          These cookies are also used to provide services you have asked for
          such as watching a video. Information collected by "functionality"
          cookies may or may not be anonymised, but they cannot track your
          browsing activity on other websites. The "functionality" cookies on
          our website only collect anonymous information.{" "}
        </p>

        <CookiesTable
          rows={[
            [
              "Token",
              "",
              "These cookies are used to collect store authentication token for the user after login/signup, it is JWT Token critical for access to all protected apis",
              "Persistent",
              "",
            ],
          ]}
        />

        <CookiesTable
          rows={[
            [
              "__stripe_mid",
              "",
              "Fraud prevention cookies added by stripe that they deploy through our Site, help them learn things about computers and web browsers used to access Stripe Services. This information helps them monitor for and detect potentially harmful or illegal use of their Services as we a c c e p t s t r i p e p a y m e n t s f o r subscriptions on school hack",
              "Persistent",
              <a
                target="_blank"
                href="https://stripe.com/ae/cookie-settings"
                className="text-blue-400 underline"
              >
                https://stripe.com...
              </a>,
            ],
          ]}
        />

        <p className="px-2 underline pt-3 text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px]">
          Targeting
        </p>
        <p className="px-2 pt-3 text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2">
          These cookies collect information about your browsing habits to make
          advertising relevant to you and your interests. These cookies collect
          the most information about users.{" "}
        </p>

        <CookiesTable
          rows={[
            ["N/A", "", "", "", ""],
            ["N/A", "", "", "", ""],
          ]}
        />
      </div>
    </div>
  );
};

export default CookiesPolicy;
