export const PrivacyList = ({ title, points }) => {
  return (
    <div className="mb-10">
      <h3 className="flex text-[20px] md:text-[24px] leading-[28px] md:leading-[32px] font-bold pt-4 pb-2 px-2">
        {title}
      </h3>
      <ul className="pt-5 pb-2 !list-disc !pl-10">
        {points.map((point) => (
          <li
            className="dot px-2 text-[16px] md:text-[20px] leading-[28px] md:leading-[32px] mb-2"
            key={point}
          >
            {point}
          </li>
        ))}
      </ul>
    </div>
  );
};
