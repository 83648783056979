import { Section } from '../../components/Section';
import { ArrowIcon } from '../../components/icons';
import { TOOLS_LINKS } from '../../constatnts';

const FeatureList = () => (
  <Section className="flex flex-col items-start lg:items-center justify-between gap-[1.56rem] lg:gap-[4.44rem] py-[3.2rem] lg:flex-row">
    <div className="flex w-full lg:w-1/2 flex-col gap-[1.56rem] lg:gap-[4.44rem]">
      <h3 className="text-[2.625rem] lg:text-[5.03444rem] font-medium lg:leading-[5.625rem] tracking-[0.09431rem]">
        AI-powered <br /> social educational platform
      </h3>
      <div className="flex flex-col gap-[1rem] min-[1024px]:flex-col rounded-[2.25rem] border border-accent lg:border-[#7D7D7D] p-[1.62rem] lg:py-[1.90rem] lg:px-[2.25rem] min-[1400px]:flex-row">
        <p className="flex items-center min-[1400px]:min-w-[50%] text-[2.25rem] text-start font-normal leading-[2.875rem] tracking-[0.05831rem]">
          Schoolhack AI Technology
        </p>
        <p className="text-start text-[1.25425rem] font-normal leading-[1.875rem] tracking-[0.02088rem] lg:text-start">
        SchoolHack interacts in a conversational way. The dialogue format makes it possible for SchoolHack to answer follow-up questions, acknowledge its mistakes, challenge incorrect premises, and decline inappropriate requests
        </p>
      </div>
    </div>

    <ul className="flex flex-col justify-center items-start gap-0 lg:gap-[1.81rem] min-w-[32.5rem] lg:flex-col">
      {TOOLS_LINKS.map(tool => (
        <li key={tool.url}>
          <a
            href={tool.url}
            target="_blank"
            rel="noreferrer noopener nofollow"
            className="group flex items-center gap-[1.5rem] lg:gap-[2.62rem]"
          >
            <div className="relative [&>svg]:max-w-[2.67rem] lg:[&>svg]:max-w-auto left-0 text-[#5ED8B2] transition-all duration-300 group-hover:left-[0.5rem]">
              <ArrowIcon />
            </div>
            <span className='text-[1.75rem] lg:text-[3rem] leading-[3.875rem] lg:leading-[6rem]'>{tool.name}</span>
          </a>
        </li>
      ))}
    </ul>
  </Section>
);

export default FeatureList;
