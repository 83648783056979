import { useEffect, useState, useRef } from 'react';

import { formatNumber, formatNumberWithCommas } from '../helpers/string';

const calculateSteps = (renders, number) => {
  const steps = Math.floor(number / renders);
  return steps > 0 ? steps : 1;
};

const CountUp = ({ number, className, shouldFormatWithComma = true }) => {
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  const steps = calculateSteps(100, number);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !isVisible) {
          setIsVisible(true);
        }
      });
    });

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const timer = setInterval(() => {
        if (count < number) {
          setCount(prevCount => prevCount + steps);
        } else {
          setCount(number);
          clearInterval(timer);
        }
      }, 10);

      return () => {
        clearInterval(timer);
      };
    } else {
      setCount(0);
    }
  }, [count, isVisible, number, steps]);

  return (
    <span ref={elementRef} className={className}>
      {shouldFormatWithComma
        ? formatNumberWithCommas(count)
        : formatNumber(count)}
    </span>
  );
};

export default CountUp;
