import { useState } from 'react';

import HelloImg from '../assets/images/webp/Hello.webp';

const Contactus = () => {
  const [name, setName] = useState('');
  const [question, setQuestion] = useState('');
  const [body, setBody] = useState('');

  const onClickSendMessage = e => {
    if (!name || !question || !body) {
      e.preventDefault();
      return;
    }
  };

  return (
    <div className="relative z-[2] bg-white py-10 lg:pb-[193px] lg:pt-[75px]">
      <div className="mx-auto max-w-lg lg:max-w-6xl 2xl:max-w-[1440px] ">
        <span className="mt-[44px] block text-center text-[16px] sm:text-[20px] sm:leading-[41px] lg:text-[30px] ">
          Get in Touch
        </span>
        <h1 className="text-center text-[40px] font-bold sm:text-[70px] sm:leading-[140px] lg:text-[120px] lg:leading-[164px] ">
          CONTACT US
        </h1>
        <div className="relative mt-[60px] grid gap-10 px-4 lg:mt-[106px] lg:grid-cols-2 lg:gap-32 ">
          <div className="bg-shadow1"></div>
          <div>
            <h2 className="text-center text-[24px] font-bold leading-[55px] text-[#1E1E1E] sm:text-[28px] md:text-[40px] lg:text-left ">
              Need Help? Say Hello
            </h2>
            <span className="mb-[90px] block text-center leading-[40px] text-[#1E1E1E] sm:text-[18px] md:text-[22px] lg:text-left ">
              Send us an email and we will get back to you.
            </span>
            <img src={HelloImg} alt="Hello" />
          </div>
          <div>
            <div className="mb-5 sm:mb-[34px] md:mb-[42px]">
              <span className="mb-[6px] ml-2 block text-[16px] leading-[27px] text-black sm:mb-[13px] sm:text-[20px]">
                Full Name
              </span>
              <input
                aria-label="name"
                value={name}
                onChange={e => {
                  setName(e.target.value);
                }}
                className="relative z-[2] w-full rounded-[10px] bg-white px-3 py-3 text-[17px] text-black outline-none focus:outline-none"
              />
            </div>
            <div className="mb-5 sm:mb-[34px] md:mb-[42px]">
              <span className="mb-[6px] ml-2 block text-[16px] leading-[27px] text-black sm:mb-[13px] sm:text-[20px]">
                This Question is about
              </span>
              <input
                aria-label="title"
                value={question}
                onChange={e => {
                  setQuestion(e.target.value);
                }}
                className="relative z-[2] w-full rounded-[10px] bg-white px-3 py-3 text-[17px] text-black outline-none focus:outline-none"
              />
            </div>
            <div className="mb-5 sm:mb-[34px] md:mb-[42px]">
              <span className="mb-[6px] ml-2 block text-[16px] leading-[27px] text-black sm:mb-[13px] sm:text-[20px]">
                Message
              </span>
              <textarea
                aria-label="message"
                rows={5}
                value={body}
                onChange={e => {
                  setBody(e.target.value);
                }}
                className="relative  z-[2] w-full resize-none rounded-[10px] bg-white px-3 py-3 text-[17px] text-black outline-none focus:outline-none"
              />
            </div>
            <a
              className={`${
                !name || !question || !body ? 'opacity-50' : 'opacity-1'
              } rounded-[40px] bg-black px-[30px] py-[15px] font-bold text-white`}
              onClick={onClickSendMessage}
              target="blank"
              rel="noopener noreferrer nofollow"
              href={`mailto:mk@schoolhack.ai?subject=${`${name}, ${question}`}&body=${body}`}
            >
              SEND MESSAGE
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contactus;
