import { twMerge } from "tailwind-merge";

export const PrivacyBlock = ({ title, paragraphs, className }) => {
  return (
    <div className={twMerge("mb-10", className)}>
      <h3 className="flex text-[20px] md:text-[24px] leading-[28px] md:leading-[32px] font-bold pt-4 pb-2 px-2">
        {title}
      </h3>
      <div className="pt-5 pb-2">
        {paragraphs.map((paragraph) => (
          <p
            className="px-2 text-[16px] md:text-[20px] block leading-[28px] md:leading-[32px] mb-2"
            key={paragraph}
          >
            {paragraph}
          </p>
        ))}
      </div>
    </div>
  );
};
